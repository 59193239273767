<template>
  <div>test
    <kendo-grid id="study-mapping-by-citations-grid"
            :ref="'dataExtractionListGrid'"
            style="citation-list-grid"
            :data-source="studyMappingByCitationsDataSource"
            :columns="columnsCitations"
            :selectable="selectable"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable"
            :change="modifyStudy">
    </kendo-grid>
  </div>
</template>

<script>
export default {

}
</script>
